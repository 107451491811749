import React from 'react'
import Container from '@mui/material/Container'
import { Box, Typography, Button, createTheme } from '@mui/material'
import { createMuiTheme,responsiveFontSizes,ThemeProvider } from '@mui/material'
let themes = createTheme();
themes = responsiveFontSizes(themes)
const Aboutus = () => {
  return (
    <>
    <ThemeProvider theme={themes}>

    <Container maxWidth="lg">
      <Box height={"50vh"} width={'100%'} sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Box textAlign={'center'}>
          <Typography variant="h6" align='center' p={2} fontWeight="300" fontFamily={'poppins[300]'}  sx={{ fontSize: {xs: '13px', md: '17px', xl: '17px'} }} >Ikiz Curtain is a reputable production workshop that has been providing high-quality office and hotel curtains in Ethiopia since 2019. Our experienced team is dedicated to delivering customized solutions that match your interior design concept. We use only the finest materials and craftsmanship to ensure that your curtains not only look great but also last for years to come.</Typography>
          <Button variant="outlined" size='small' href='/aboutUs'>
            Read more about us
          </Button>
          
        </Box>
      </Box>
      
    </Container>
    </ThemeProvider>

    </>
  )
}

export default Aboutus