import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
// import { auth } from "../firebase-config/firebase";
import  {auth}  from '../firebase-config/firebase'
import { Box, Button, Paper, Stack, TextField, Typography } from "@mui/material";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/adminpage");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
    
      {/* MUI */}

      <>
    <Box sx={{width: '100%' ,backgroundColor: 'lightgray', height: '100vh', display: 'flex'}}>

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}
      >
      

            <Paper  elevation={3} sx={{width: 600, height: {xs:'100vh', sm: '90vh'} , justifyContent: 'center', alignItems: 'center'}}>
                <Stack direction={'column'} p={4}>
                  
                    <Typography variant="h4" 
                sx={{marginTop: '60px',textAlign: 'center' }}
                >ADMIN LOGIN</Typography>
      {error && <p>{error}</p>}


                <TextField
                sx={{marginTop: '100px', }}
                  id=""
                  label="Email"
                  type='email'
                  //   value={}
                  onChange={(e) => setEmail(e.target.value)}
                  />
                <TextField
                sx={{marginTop: '20px', }}

                  id=""
                  label="Password"
                  type='password'
                  //   value={}
                  onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button variant="contained"  onClick={handleSubmit}
                sx={{marginTop: '20px',
                //  backgroundColor: 'orange' ,color: 'black' ,"&:hover" : {backgroundColor: 'amber'}
                }}

                  >log in
                    
                  </Button>
                  <Button variant="text"  
                // sx={{ color: 'orange', "&:hover" : {backgroundColor: 'lightyellow'}}}
                >
                    login with google.
                  </Button>
                  </Stack>
            </Paper>
        </Box>
        </Box>

      
    {/* </Container> */}
    {/* <Login/> */}
      
    </>
    </div>
  );
}

export default Login;
