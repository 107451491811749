import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
// import Appbardesktop from './Appbardesktop';
// import Appbarmobile from './Appbarmobile';
import Products from './Products';
import Productslider from './productSlider';

const Prodindex = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
     {match ? <Products/> : <Productslider/>}
    </>
  )
}

export default Prodindex;