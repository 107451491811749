// import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import {ProductData} from './DataMobile'
// import Heroimg from '../478A7697.jpg'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const arrow = {
  color: 'orange',
  fontSize:'50px',
  margin: '0 30px',
  "&:hover" : {
    color: 'grey'
  }
}


const Heromobile = () => {

  
  var settings = {
    // margin: 0,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 0,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <ArrowCircleLeftIcon sx={arrow}/>,
    nextArrow: <KeyboardArrowRightIcon  sx={arrow}/>,

  };
  return (
    <div style={{width: '100%'}}>

          <Slider  {...settings} style={{width: '100%',margin: '0'}}>


     {ProductData.map((pro, index) => {

     return       <Box sx={{height:'100vh', width: '100%', backgroundImage:  `url(${pro.image})`,backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat', alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
        <Box sx={{display: 'flex',opacity:"0.5",
      
          background:'white', marginTop: '300px',
           justifyContent: 'center' ,  }} width={"100%"}>
          <Typography
           variant="h1"
            color="primary"
          sx={{opacity:"1"}}
            py={6}
            fontWeight={'bold'} fontSize= "100px" >IKIZ</Typography>

        </Box>
      </Box>
         })}
      
      
      
      
      </Slider>
    </div>
  )
}

export default Heromobile




