import Imageone from "../image/B-bedroom.jpg"
import Imagetwo from "../image/photo1678183268 (2).jpeg"
import Imagethree from "../image/Stage curtain.jpg"
import Imagefour from "../image/G-familyroom.jpg"
import Imagefive from "../image/office curtain.jpg"

export const ProductData = [
  {
    title: " Bedroom Cutain",
    image: Imageone,
    alt: 'proimage'
  },
   {
    title: "Dinning Room",
    image: Imagetwo,
    alt: 'proimage'
  },
    
   {
    title: " Stage Curtain",
    image: Imagethree,
    alt: 'proimage'
  },
   {
    title: " Living Room",
    image: Imagefour,
    alt: 'proimage'
  },
  {
    title: " Office Curtain ",
    image: Imagefive,
    alt: 'proimage'
  }
];