import { Container } from '@mui/material'
import React from 'react'

const Map = () => {
  return (
    <Container maxWidth="xl">
      
            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d7881.172034445553!2d38.78088560956721!3d9.010185684637708!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOcKwMDAnMzYuNyJOIDM4wrA0Nic1MS4yIkU!5e0!3m2!1sam!2snl!4v1682937631671!5m2!1sam!2snl" width="100%" height="550" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </Container>
   
  )
}

export default Map