import Imageone from "./heroImg/mobile11.jpeg"
import Imagetwo from "./heroImg/mobile3.jpeg"
import Imagethree from "./heroImg/mobile2.jpeg"

export const ProductData = [
  {
    title: "  BEDROOM CORTAIN",
    image: Imageone,
    alt: 'proimage'
  },
   {
    title: "DINNING ROOM",
    image: Imagetwo,
    alt: 'proimage'
  },
    
   {
    title: "  STAGE CURTAIN",
    image: Imagethree,
    alt: 'proimage'
  },


];