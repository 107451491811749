import missimg from '../image/IMG_20230307_124928_063.jpg'
import successes from '../image/IMG_20230307_124924_128.jpg'
export const AboutData = [
  {
    missitionimage: missimg,
    successesimage: successes,
    title: "About Us",
    detail1: '   Izik Curtain is a well-established production workshop that has been operating in Ethiopia since 2019. Our company specializes in producing high-quality curtains for offices and hotels, designed to enhance interior aesthetics. We take pride in offering a wide range of options to suit our clients needs and preferences.',
  detail2: '   To promote our business and increase our customer base, we are continuously improving our production processes and product quality. Our team of experienced professionals ensures that each order is fulfilled with the utmost attention to detail and craftsmanship.' ,
  detail3: '   At Izik Curtain, we believe that our commitment to excellence and customer satisfaction sets us apart from our competitors. We strive to maintain a strong reputation in the industry by providing outstanding service and products. We are confident that our dedication to quality and professionalism will continue to drive our success in the future.',
    subtitle:'IZIK CURTAIN',
    missionDetail: 'The mission of our company is to deliver the highest quality workmanship to our clients. We are committed to meeting agreed-upon delivery dates and executing our clients\' projects in strict accordance with their interior design concepts. We believe that our attention to detail, commitment to excellence, and customer satisfaction are the keys to our success. Our goal is to exceed our clients\' expectations and establish long-term relationships based on trust and mutual respect.',
    successesDetail: 'Over the years, Izik Curtain has achieved numerous successes in the industry. Our commitment to providing the highest quality workmanship and excellent customer service has earned us a reputation as a trusted and reliable provider of curtains for offices and hotels. We have had the privilege of working with many prestigious clients and have completed a wide range of projects, from small office spaces to large hotel complexes.',
    

    successesDetail2: "One of our major successes has been our ability to stay ahead of industry trends and provide our clients with innovative solutions. We are constantly researching and developing new products and techniques to enhance our offerings and meet the changing needs of our clients. Our team of experienced professionals is always striving to improve our processes and exceed customer expectations.There are a few factors that spell success or failure for professional workroom, that is most of which stem from the importance of reputation to the work of the designer. So, some of our success came from meeting deadlines and executing quality products as promised."
    
  }
]