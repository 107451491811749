import Aboutus from "../../components/aboutus/Aboutus"
import Contact from "../../components/contact/Contact"
import Hero from "../../components/hero/Hero"
import HeroSlider from "../../components/hero/heroSlider"
// import Swiper from "../../components/hero/swiper"
import Mediaindex from "../../components/media/mediaIndex"
import Prodindex from "../../components/products.js/prodIndex"
import Products from "../../components/products.js/Products"
import Productslider from "../../components/products.js/productSlider"
import ServHead from "../../components/servicesHead/ServHead"

import Service from "../../components/Service/Service"
import HeroIndex from "../../components/hero"


const Home = () => {
  return (
    <>
   
   
   {/* <HeroSlider/> */}
   <HeroIndex/>
   <Aboutus/>
   <ServHead/>
   <Prodindex/>
   <Mediaindex/>
   
   <Service/>
   <Contact/>
   
    </>
  )
}

export default Home