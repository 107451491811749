import React from 'react'
import { Router,Outlet } from 'react-router-dom'
// import { Outlet } from "react-router"
import Login from './Login';
import { auth}  from '../firebase-config/firebase';
import LoginPage from './LoginPage';
// const useAuth = () => {
//   const user = {loggin : false}
//   return user && user.loggin;
// }


const useAuth = () => {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return user !== null;
};
const ProteceRouter = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet/> : <Login/>

}

export default ProteceRouter;