// import * as React from 'react';
import { Box, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React from 'react'
// import ServiceCard from './ServiceCard';
// import {ServiceData} from '../../Data/ServiceData' ;

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
// import Img from '../../image/installation.jpg'

import cur from './dliver.png'
import ins from './ins.png'
import sew from './wep.png'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));




const Service = () => {
  return (
    <Box justifyContent={'center'} display="flex">
      <Grid container spacing={2} my ={4}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
        <Paper sx={{textAlign: 'center' , bgcolor: 'transparent', }} elevation={0}>
        {/* <CardMedia
        component="img"
        height='140'
        image={cur}
        alt='image'/> */}

        <img alt='asdf' src={cur} height="100" />

        
        <CardContent>
          <Typography variant="h5"  mb={2}  >
            Free Delivery
          </Typography>
          <Typography variant='subtitle1' sx={{fontSize: '15px'}}>
         Ikiz Curtain offers free delivery to ensure a hassle-free process for our customers, delivering curtains safely and efficiently without additional charges.
          </Typography>
        </CardContent>
      </Paper>

        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
        <Paper sx={{textAlign: 'center' , bgcolor: 'transparent', }} elevation={0}>
        <img alt='asdf' src={sew} height="100" />
        <CardContent>
          <Typography variant="h5"  mb={2} >
            Curtain Design
          </Typography>
          <Typography variant='subtitle1'  sx={{fontSize: '15px'}}>
          Custom-made curtain designs by Ikiz Curtain to complement your unique style and interior design concept, using high-quality materials and meticulous attention to detail.
          </Typography>
        </CardContent>
      </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} >
        <Paper sx={{textAlign: 'center' , bgcolor: 'transparent', }} elevation={0}>
        <img alt='asdf' src={ins} height="100" />

        
        <CardContent>
          <Typography variant="h5" mb={2} >
          Curtain Installation
          </Typography>
          <Typography variant='subtitle1' sx={{fontSize: '15px'}}>
          Professional curtain installation services provided by Ikiz Curtain to ensure a flawless finish, from precise measurements to secure installation.
          </Typography>
        </CardContent>
      </Paper>

        </Grid>

      </Grid>

    </Box>
  )
}
export default Service