import { useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import Heromobile from './heroslidermobile';
import HeroSlider from './heroSlider';
const HeroIndex = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
     {match ? <Heromobile/> : <HeroSlider/>}
    </>
  )
}
export default HeroIndex