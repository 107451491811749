
import React from 'react'
import Container from '@mui/material/Container'
import { Box, Typography, Button } from '@mui/material'
import { AboutData } from '../../Data/AboutData'
const Aboutdetail = () => {
  return (
    <>
    <Container maxWidth="md" >
      <Box height={"50vh"} width={'100%'} sx={{display:'flex', justifyContent:'center', alignItems:'center', paddingY: '10px'}}>
        {AboutData.map((data, index) => {
          return (
          <Box textAlign={'start'}>
          <Typography variant="body1" align='start'pt={2} pb={0} fontWeight="300" sx={{fontSize:{xs:"13px", sm:'14px', md: '15px' }}} >{data.detail1}</Typography>
          <Typography variant="body1" align='start'pt={1} pb={0} fontWeight="300" sx={{fontSize:{xs:"13px", sm:'14px', md: '15px' }}} >{data.detail2}</Typography>
          <Typography variant="body1" align='start'pt={1} pb={4} fontWeight="300" sx={{fontSize:{xs:"13px", sm:'14px', md: '15px' }}} >{data.detail3}</Typography>
          <Button variant="outlined" size='large' href='./contact'>Contact us
          </Button>
        </Box>)
})}
      </Box>
    </Container>
    </>
  )
}
export default Aboutdetail