import { Box, Button, Container, Grid, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import BG from "../../image/liquid-cheese.png"
import CopyrightIcon from '@mui/icons-material/Copyright';

const Footer = () => {
  return (
    <div>
      <Box width={'100%'} height={'auto'} sx={{
        background:'#D29F22' ,
        backgroundImage:  `url(${BG})`,
        marginTop:'100px',
         paddingTop:'50px',
         color: 'black'
         }}>
        <Container maxWidth="xl" sx={{display:'flex', alignItems:'center', justifyContent: 'center'}}>
        <Grid container py={1}>
            <Grid item xs="12" sm='12' md="4" >
              <Typography variant="h6" color="black" fontFamily={'poppins'}>About us</Typography>
              <Typography variant="subtitle1" color="black" paddingRight={4} fontWeight={'250'} fontFamily={'poppins'}>Ikiz Curtain is a well-established production workshop that has been operating in Ethiopia since 1998. Our company specializes in producing high-quality curtains for offices and hotels, designed to enhance interior aesthetics. We take pride in offering a wide range of options to suit our clients needs and preferences.</Typography>
            </Grid>
            <Grid item
             xs="12"
             sm='12'
             md="4" 
            //  px={'50px'}
                >
              <Typography variant="h6" color="black" fontFamily={'poppins'} >Phone Number</Typography>
        <Typography variant="subtitle1" color={'black'} fontWeight={'250'} fontFamily={'poppins'}
        >+251-955-337-777</Typography> 
        <Typography variant="subtitle1" color={'black'} fontWeight={'250'} fontFamily={'poppins'}
        >+251-911-764-944</Typography>
        <Typography variant="subtitle1" color={'black'} fontWeight={'250'} fontFamily={'poppins'}
        >+251-923-586-960</Typography>
        <Typography variant="subtitle1" color={'black'} fontWeight={'250'} fontFamily={'poppins'}
        >+251-920-679-023</Typography>
            </Grid>
            <Grid item xs="12" sm='12' md="4"  >
              
              <Typography variant="h6" color="black" fontFamily={'poppins'}>Our Address</Typography>
              <Typography variant="subtitle1" color="black" fontFamily={'poppins'}
              fontWeight={'250'}>On the road leading to the 22 bridge from Bole Atlas mebrat</Typography>
              <Typography variant="h6" color="black" fontFamily={'poppins'}>Write Us</Typography>
              <Typography variant="subtitle1" color="black"  fontWeight={'250'} fontFamily={'poppins'}>Ikizcurtain@gmail.com</Typography>
              <Typography variant="h6" color="black" fontFamily={'poppins'}>Follow Us</Typography>
              
                <Stack direction={'row'} spacing={2}>
                  <IconButton href='/'>
                  <FacebookIcon color='black' sx={{fontSize:'40px'}}/>
                   </IconButton>
                   <IconButton>
                  <InstagramIcon color='black' sx={{fontSize:'40px'}}/>
                   </IconButton>
                   <IconButton href='https://t.me/ikizcurtain'>
                  <TelegramIcon color='black' sx={{fontSize:'40px'}}/>
                   </IconButton>
                   <IconButton href='https://www.tiktok.com/@ikiz_curtain?_t=8auGvVIqJaX&_r=1'>
                   <img src="https://img.icons8.com/ios/50/000000/tiktok--v1.png"/>
                   </IconButton>
                </Stack>
            </Grid>
          </Grid>
        </Container>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'  }} paddingTop={2}>
          <CopyrightIcon fontSize='small'/>

        <Typography variant="subtitle1" fontWeight={100} > Ikiz curatin.    </Typography>
        <Typography variant="subtitle1" fontWeight={100} > developed by esrael ermias(<a href='https://t.me/Izzy_Lucky' style={{textDecorationLine: 'none', color : 'black'}}>contact me</a>)  </Typography>
        </Box>
      </Box>

    </div>
  )
}

export default Footer