import React from 'react'
import Gallery from '../../components/ourWorkComponent/Gallery'
import OurWorkheader from '../../components/ourWorkComponent/ourWorkheader'

const OurWorks = () => {
  return (
    <div>
      <OurWorkheader/>
      <Gallery/>
    </div>
  )
}

export default OurWorks