import Imageone from "./heroImg/hero11.jpg"
import Imagetwo from "./heroImg/hero22.jpg"
import Imagethree from "./heroImg/hero.jpg"

export const ProductData = [
  {
    title: "  BEDROOM CORTAIN",
    image: Imageone,
    alt: 'proimage'
  },
   {
    title: "DINNING ROOM",
    image: Imagetwo,
    alt: 'proimage'
  },
    
   {
    title: "  STAGE CURTAIN",
    image: Imagethree,
    alt: 'proimage'
  },


];