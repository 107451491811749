// import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';
import {ProductData} from '../../Data/ProductData'

const arrow1 = {
  color: 'orange',
  fontSize:'50px',
  margin: '10px 10px 10px -50px',
  "&:hover" : {
    color: 'orange',
    fontSize:'55px',
  }
}
const arrow2 = {
    color: 'orange',
    fontSize:'50px',
    margin: '10px -50px 10px 500px',
    "&:hover" : {
      color: 'orange',
    fontSize:'55px',

    }
  }



const Productslider = () => {

  
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <ArrowCircleLeftIcon sx={arrow1}/>,
    nextArrow: <ArrowCircleRightIcon  sx={arrow2}/>,
   responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
  };
  return (
    <div>
    <Box alignContent={"center"}>
        <Container maxWidth="lg">
          <Typography variant="h6" sx={{ fontSize: { xs: '20px', md: '30px' } }} fontFamily={'poppins'} fontWeight={"bold"} color="primary" pt={5} pb={5}>SAMPLE PRODUCTS</Typography>
    <Slider {...settings}>
     {ProductData.map((pro, index) => {
       return (
           <Container maxWidth="lg">         
            <Card sx={{ maxWidth: 345, margin: '10px' }}  elevation="0.2" href='/work'>
             <CardActionArea>
               <CardMedia
                 component="img"
                 height="200"
                 image={pro.image}
                 alt="pruducts"
               />
               <CardContent sx={{padding: '0px'}} >
                 <Typography gutterBottom variant="h6" flex justifyContent='center' component="div" paddint="10px" pb={0}>
                   {pro.title}
                 </Typography>
                 <CardActions sx={{padding: '2px'}}>
                   <Button size="small" color="primary" href='/work'>
                     see more
                   </Button>
                 </CardActions>
               </CardContent>
             </CardActionArea>
           </Card>
           </Container>
       )
     })}
</Slider>
   </Container>
      </Box>
    </div>
  )
}
export default Productslider